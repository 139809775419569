<template>
  <div>
    <welcome-conference-banner v-if='showConferenceBanner' class='mb-8'/>
    <div class='px-4 lg:px-0'>
      <div class='border-2 border-gray-100 px-4 lg:px-8 py-8 rounded-lg shadow-md text-center font-light'>
        <p class='text-xl lg:text-2xl'>등록 기간: <span class='block lg:inline font-semibold text-red-600'>2024년 1월 29일(월) 자정까지</span></p>
        <p class='mt-4 text-sm text-gray-900'>등록 마감일 이후 미입금으로 확인되시는 경우, 등록이 자동 취소되오니 등록 마감일 전까지 입금 부탁 드립니다.</p>
        <div class='mx-auto text-lg lg:text-xl mt-4 underline font-bold text-red-600'>
          <router-link :to='{ name: "Registration" }'>등록하러 가기</router-link>
        </div>
        <div class='mt-4 lg:mt-12 flex flex-col justify-start items-start'>
          <h2 class='mt-2 font-bold w-full'>문의처</h2>
          <div class='w-full'>
            <div class='whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6 flex flex-col lg:flex-row justify-center gap-x-8'>
              <div>T. 02-6734-1012 / 1015</div>
              <div>E. cmcimt@planbear.co.kr</div>
            </div>
          </div>
        </div>
      </div>

      <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
        <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/4'>유의 사항</h2>
        <div class='w-full lg:w-3/4 pl-6 lg:pl-0'>
          <ul class='list-disc list-inside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
            <li class='mt-2 mb-6'>
              반드시 <span class='font-semibold text-red-600'>등록 양식을 작성하여 등록을 완료하신 후에 등록비를 납부</span>하여 주십시오.
            </li>
            <li class='mt-2 mb-6'>
              입금 시, <span class='font-semibold text-red-600'>등록자명(소속)으로 입금</span> 하여 주십시오. <span class='font-semibold'>등록자명과 입금자명이 상이한 경우, 등록자 미상으로 입금 확인이 어렵습니다.</span>
            </li>
          </ul>
        </div>
      </div>
      <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
        <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/4'>평점 안내</h2>
        <div class='w-full lg:w-3/4 pl-6 lg:pl-0'>
          <ul class='list-disc list-inside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
            <li class='mt-2 mb-6'>
              대한의사협회 연수교육 평점 6평점
            </li>
            <li class='mt-2 mb-6'>
              <span class='font-semibold text-red-600'>2월 4일(일) 하루 동안(00:00~23:59)의 VOD 시청 기록의 총합으로 대한의사협회 연수교육 평점을 최대 6평점 취득하실 수 있습니다.</span>
            </li>
            <li class='mt-2 mb-6'>
              본 연수강좌는 온라인 VOD 송출 방식으로, <span class='font-semibold text-red-600'>강의 입장 및 퇴장 버튼이 없습니다.</span><br><span class='font-semibold'>2월 4일(일) 내에 원하시는 시간에 접속하시어</span> 원하시는 강의를 시청해 주시면 됩니다. 강의 완강 시, 시청하신 시간이 기록됩니다.
            </li>
            <li class='mt-2 mb-6'>
              수강 시간에 따른 부분 평점 인정 (최대 6평점)<br>
              1시간 미만 : 0평점<br>
              1시간 이상 ~ 2시간 미만 : 1평점<br>
              2시간 이상 ~ 3시간 미만 : 2평점<br>
              3시간 이상 ~ 4시간 미만 : 3평점<br>
              4시간 이상 ~ 5시간 미만 : 4평점<br>
              5시간 이상 ~ 6시간 미만 : 5평점<br>
              6시간 이상 : 6평점 
            </li>
          </ul>
        </div>
      </div>
      <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
        <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/4'>등록비 안내</h2>
        <div class='w-full lg:w-3/4 pl-6 lg:pl-0'>
          <table class='mt-2 mb-8 registration-guideline-table border-t border-l text-center text-sm lg:text-base'>
              <thead class='bg-gray-100'>
                <tr>
                  <th class='w-1/4'>구분</th>
                  <th class='w-1/5'>개원의, 봉직의</th>
                  <th class='w-1/4'>공보의, 군의관, 전공의, 간호사, 기타</th>
                  <th class='w-1/4 bg-yellow-100'>다시보기<br>
                    <p class='text-xs text-red-600'>* 평점 취득 불가</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class='bg-gray-100'>온라인 72시간<br><p class='text-xs'>2024.2.4(일) ~ 2024.2.6(화)</p></th>
                  <td>5만원</td>
                  <td>3만원</td>
                  <td>2일<br>
                    <p class='text-xs'>2024.2.5(월) ~ 2024.2.6(화)</p>
                  </td>
                </tr>
                <tr>
                  <th class='bg-gray-100'>온라인 2주<br><p class='text-xs'>2024.2.4(일) ~ 2024.2.18(일)</p></th>
                  <td>7만원</td>
                  <td>4만원</td>
                  <td>14일<br>
                    <p class='text-xs'>2024.2.5(월) ~ 2024.2.18(일)</p>
                  </td>
                </tr>
                <tr>
                  <th class='bg-gray-100'>교재 인쇄본 별매<br><p class='text-xs text-red-600'>* 등록자 전원에게<br>PDF 교재 무상 제공</p></th>
                  <td class='text-left' colspan='4'>
                    <ul class='list-disc list-inside whitespace-pre-line text-gray-600 leading-6'>
                      <li class='my-2 line-through' style='text-decoration-color: red; text-decoration-thickness: 3px;'>
                        2만원 (인쇄본 교재 발송비 포함)
                      </li>
                      <li class='my-2 line-through' style='text-decoration-color: red; text-decoration-thickness: 3px;'>
                        신청 방법: 등록 시, 교재 구매 선택 <span class='font-medium text-sm text-red-600'>(교재 신청 기간: 2024.1.25(목) 자정까지)</span>
                      </li>
                      <li class='my-2 text-red-600'>
                        <span class='font-semibold'>인쇄본 교재 구매가 마감되었습니다.</span> 무상 제공되는 PDF 교재를 이용 부탁 드립니다.
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          <ul class='list-disc list-inside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
            <li class='mt-2 mb-6'>
              <span class='font-semibold'>2월 4일(일) 강의 최초 1회 시청 시, 평점을 위한 시청 기록 측정을 위하여 강의의 재생 위치를 임의로 이동할 수 없습니다.</span>
            </li>
            <li class='mt-2 mb-6'>
              <span class='font-semibold'>2월 4일(일) 강의를 최초 1회 시청한 이후에 다시 보시는 경우에는 재생 위치를 임의로 이동하며 자유롭게 시청하실 수 있습니다.</span>
            </li>
            <li class='mt-2 mb-6'>
              <span class='font-semibold'>다시보기 기간에는 자유롭게 시청하실 수 있습니다.</span>
            </li>
            <li class='mt-2 mb-6'>
              <span class='font-semibold text-red-600'>다시보기 기간 동안 시청하신 강의에 대해서는 평점이 인정되지 않고, 질문을 받지 않습니다.</span>
            </li>
            <li class='mt-2 mb-6'>
              <span class='font-semibold'>강의에 대한 <span class='text-red-600'>질의는 2월 4일(일) 하루 동안만 가능</span>합니다.</span>
            </li>
          </ul>
        </div>
      </div>
      <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
        <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/4'>등록비 납부 방법</h2>
        <div class='w-full lg:w-3/4 pl-6 lg:pl-0'>
          <table class='mt-2 mb-8 registration-guideline-table border-t border-l text-center text-sm lg:text-base w-full'>
            <tbody>
              <tr>
                <th class='bg-gray-100'>은행명</th>
                <td>우리은행</td>
              </tr>
              <tr>
                <th class='bg-gray-100'>계좌번호</th>
                <td>1005-103-454402</td>
              </tr>
              <tr>
                <th class='bg-gray-100'>예금주</th>
                <td>가톨릭대학교내과학교실진흥회</td>
              </tr>
            </tbody>
          </table>
          <ul class='list-disc list-inside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
            <li class='mt-2 mb-6'>
              <span class='font-semibold text-red-600'>결제는 계좌이체만 가능</span>합니다. (<span class='font-semibold text-red-600'>신용카드 결제는 불가</span>합니다.)
            </li>
            <li class='mt-2 mb-6'>
              반드시 <span class='font-semibold text-red-600'>등록하신 후에 등록비를 납부</span>하여 주십시오.
            </li>
            <li class='mt-2 mb-6'>
              입금 시, <span class='font-semibold text-red-600'>등록자명(소속)으로 입금</span> 하여 주십시오. <span class='font-semibold'>등록자명과 입금자명이 상이한 경우, 등록자 미상으로 입금 확인이 어렵습니다.</span>
            </li>
          </ul>
        </div>
      </div>
      <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
        <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/4'>등록 절차</h2>
        <div class='w-full lg:w-3/4 pl-6 lg:pl-0'>
          <ul class='list-decimal list-inside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6 pl-6 lg:pl-0'>
            <li class='mt-2 mb-6'>
              등록 양식 작성 후 등록
            </li>
            <li class='mt-2 mb-6'>
              등록비 입금
            </li>
            <li class='mt-2 mb-6'>
              사무국에서 등록비 입금 여부 확인(<span class='font-semibold'>영업일 기준 3~5일 소요</span>)
            </li>
            <li class='mt-2 mb-6'>
              등록 완료(최종 등록 완료 안내 메일 수신 혹은 마이페이지 내 ‘영수증 다운로드’를 통해 확인 가능)
            </li>
          </ul>
        </div>
      </div>
      <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
        <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/4'>안내 사항</h2>
        <div class='w-full lg:w-3/4 pl-6 lg:pl-0'>
          <table class='mt-2 mb-8 registration-guideline-table border-t border-l text-center text-sm lg:text-base w-full'>
            <tbody>
              <tr>
                <th class='bg-gray-100'>영수증</th>
                <td class='text-left'>
                  <ul class='list-disc list-inside whitespace-pre-line text-gray-600 leading-6'>
                    <li class='my-2'>
                      <span class='font-semibold text-red-600'>현금 영수증 및 세금 계산서 발행은 불가</span>합니다.
                    </li>
                    <li class='my-2'>
                      입금 완료 시, 등록비 영수증은 마이페이지 내 ‘영수증 다운로드‘를 통해 직접 출력하실 수 있습니다. 
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th class='bg-gray-100'>환불</th>
                <td class='text-left'>
                  <ul class='list-disc list-inside whitespace-pre-line text-gray-600 leading-6'>
                    <li class='my-2'>
                      <span class='font-semibold text-red-600'>사전등록 마감일 이후에는 환불이 불가합니다.</span>
                    </li>
                    <li class='my-2'>
                      환불은 연수강좌가 개최된 이후에 진행됩니다.
                    </li>
                    <li class='my-2'>
                      환불을 희망하시는 경우 환불 받으실 계좌 정보 및 환불 사유를 cmcimt@planbear.co.kr 로 보내주시기 바랍니다.
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
        <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/4'>문의처</h2>
        <div class='w-full lg:w-3/4 pl-6 lg:pl-0'>
          <ul class='list-disc list-inside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6 pl-6 lg:pl-0'>
            <li class='mt-2 mb-6'>
              2024년 제17회 가톨릭대학교 의과대학 내과 연수강좌 준비사무국<br>
              T. 02-6734-1012 / 1015<br>
              E. cmcimt@planbear.co.kr
            </li>
          </ul>
        </div>
      </div>
      <div class='mt-8 lg:mt-24  border-2 border-gray-100 px-4 lg:px-8 py-8 rounded-lg shadow-md text-center font-light'>
        <p class='text-xl lg:text-2xl'>등록 기간: <span class='block lg:inline font-semibold text-red-600'>2024년 1월 29일(월) 자정까지</span></p>
        <p class='mt-4 text-sm text-gray-900'>등록 마감일 이후 미입금으로 확인되시는 경우, 등록이 자동 취소되오니 등록 마감일 전까지 입금 부탁 드립니다.</p>
        <div class='mx-auto text-lg lg:text-xl mt-4 underline font-bold text-red-600'>
          <router-link :to='{ name: "Registration" }'>등록하러 가기</router-link>
        </div>
        <div class='mt-4 lg:mt-12 flex flex-col justify-start items-start'>
          <h2 class='mt-2 font-bold w-full'>문의처</h2>
          <div class='w-full'>
            <div class='whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6 flex flex-col lg:flex-row justify-center gap-x-8'>
              <div>T. 02-6734-1012 / 1015</div>
              <div>E. cmcimt@planbear.co.kr</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <welcome-sponsor-carousel v-if='showSponsorCarousel' class='mt-8 lg:mt-24' />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'RegistrationGuideline',
  components: {
    'welcome-conference-banner': () => import('@/components/welcome/WelcomeConferenceBanner.vue'),
    'welcome-sponsor-carousel': () => import('@/components/welcome/WelcomeSponsorsCarousel.vue'),
  },
  props: {
    defaultToToday: {
      type: Boolean,
      default: false,
    },
    needToBeLoggedIn: {
      type: Boolean,
      default: false,
    },
    showConferenceBanner: {
      type: Boolean,
      default: true,
    },
    showSponsorCarousel: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
  },
}
</script>

<style type='scss' scoped>
  .registration-guideline-table tr {
    @apply border-b;
  }

  .registration-guideline-table td {
    @apply border-r p-1 lg:p-4;
  }

  .registration-guideline-table th {
    @apply border-r p-1 lg:p-2;
  }
</style>
